import { useEffect, useRef, useState } from "react";
import "./App.css";

import * as echarts from "echarts";
// import * as echarts from "echarts/core";
// import { TooltipComponent, VisualMapComponent } from "echarts/components";
// import { CustomChart } from "echarts/charts";
// import { CanvasRenderer } from "echarts/renderers";

import XLSX from "xlsx";
import "echarts/extension/bmap/bmap";
import axios from "axios";

// echarts.use([
//   TooltipComponent,
//   VisualMapComponent,
//   CustomChart,
//   CanvasRenderer,
// ]);

var COLORS = ["#98cafb", "#80aaee", "#6180dd", "#3f53ca", "#202ab9", "#000091"];
// var lngExtent = [39.5, 40.6];
// 经度
const latExtent = [37.189279, 40.183522];
// var latExtent = [115.9, 116.8];
// 维度
const lngExtent = [115.335017, 118.500708];
// var cellCount = [50, 50];
// var cellSizeCoord = [
//   (lngExtent[1] - lngExtent[0]) / cellCount[0],
//   (latExtent[1] - latExtent[0]) / cellCount[1],
// ];
const cellSizeCoord = [0.02, 0.02];
const cellCount = [
  Math.ceil((lngExtent[1] - lngExtent[0]) / cellSizeCoord[0]),
  Math.ceil((latExtent[1] - latExtent[0]) / cellSizeCoord[1]),
];
console.log(cellCount);
// 按钮租
const buttons = {
  position: {
    title: "工厂位置",
    pieces: [
      {
        min: 0,
        max: 0,
        color: "#fff",
      },
      {
        min: 1,
        max: 10,
        color: COLORS[0],
      },
      {
        min: 11,
        max: 20,
        color: COLORS[1],
      },
      {
        min: 21,
        max: 30,
        color: COLORS[2],
      },
      {
        min: 31,
        max: 40,
        color: COLORS[3],
      },
      {
        min: 41,
        max: 50,
        color: COLORS[4],
      },
      {
        min: 51,
        color: COLORS[5],
      },
    ],
  },
  leiji: {
    title: "累计出货量",
    pieces: [
      {
        min: 0,
        max: 0,
        color: "#fff",
      },
      {
        min: 1,
        max: 50,
        color: COLORS[0],
      },
      {
        min: 51,
        max: 100,
        color: COLORS[1],
      },
      {
        min: 101,
        max: 150,
        color: COLORS[2],
      },
      {
        min: 151,
        max: 200,
        color: COLORS[3],
      },
      {
        min: 201,
        max: 250,
        color: COLORS[4],
      },
      {
        min: 251,
        color: COLORS[5],
      },
    ],
  },
  bannian: {
    title: "半年出货量",
    pieces: [
      {
        min: 0,
        max: 0,
        color: "#fff",
      },
      {
        min: 1,
        max: 50,
        color: COLORS[0],
      },
      {
        min: 51,
        max: 100,
        color: COLORS[1],
      },
      {
        min: 101,
        max: 150,
        color: COLORS[2],
      },
      {
        min: 151,
        max: 200,
        color: COLORS[3],
      },
      {
        min: 201,
        max: 250,
        color: COLORS[4],
      },
      {
        min: 251,
        color: COLORS[5],
      },
    ],
  },
};
let cellData = {};
function App() {
  const dom = useRef();
  const cheart = useRef(null);
  // 网格热力图与普通热力图
  const type = useRef("custom");
  // 数据类型
  const dataType = useRef("position");
  // 屏幕坐标数据
  const boundData = useRef(null);
  const bmapObj = useRef(null);
  const init = useRef(true);
  const fileData = useRef([]);
  const options = useRef({
    tooltip: {},
    visualMap: {
      type: "piecewise",
      inverse: true,
      top: 50,
      left: 10,
      pieces: [],
      borderColor: "#ccc",
      borderWidth: 2,
      backgroundColor: "#eee",
      dimension: 2,
      inRange: {
        color: COLORS,
        opacity: 0.7,
      },
    },
    series: [
      {
        // type: "custom",
        type: type.current,
        coordinateSystem: "bmap",
        renderItem: renderItem,
        animation: false,
        emphasis: {
          itemStyle: {
            color: "yellow",
          },
        },
        encode: {
          tooltip: 2,
        },
        data: [],
      },
    ],
    bmap: {
      center: [117.2103, 39.006454],
      zoom: 9.999,
      roam: true,
      // mapStyle: {
      //   styleJson: [
      //     {
      //       featureType: "water",
      //       elementType: "all",
      //       stylers: {
      //         color: "#d1d1d1",
      //       },
      //     },
      //     {
      //       featureType: "land",
      //       elementType: "all",
      //       stylers: {
      //         color: "#f3f3f3",
      //       },
      //     },
      //     {
      //       featureType: "railway",
      //       elementType: "all",
      //       stylers: {
      //         visibility: "off",
      //       },
      //     },
      //     {
      //       featureType: "highway",
      //       elementType: "all",
      //       stylers: {
      //         color: "#999999",
      //       },
      //     },
      //     {
      //       featureType: "highway",
      //       elementType: "labels",
      //       stylers: {
      //         visibility: "off",
      //       },
      //     },
      //     {
      //       featureType: "arterial",
      //       elementType: "geometry",
      //       stylers: {
      //         color: "#fefefe",
      //       },
      //     },
      //     {
      //       featureType: "arterial",
      //       elementType: "geometry.fill",
      //       stylers: {
      //         color: "#fefefe",
      //       },
      //     },
      //     {
      //       featureType: "poi",
      //       elementType: "all",
      //       stylers: {
      //         visibility: "off",
      //       },
      //     },
      //     {
      //       featureType: "green",
      //       elementType: "all",
      //       stylers: {
      //         visibility: "off",
      //       },
      //     },
      //     {
      //       featureType: "subway",
      //       elementType: "all",
      //       stylers: {
      //         visibility: "off",
      //       },
      //     },
      //     {
      //       featureType: "manmade",
      //       elementType: "all",
      //       stylers: {
      //         color: "#d1d1d1",
      //       },
      //     },
      //     {
      //       featureType: "local",
      //       elementType: "all",
      //       stylers: {
      //         color: "#d1d1d1",
      //       },
      //     },
      //     {
      //       featureType: "arterial",
      //       elementType: "labels",
      //       stylers: {
      //         visibility: "off",
      //       },
      //     },
      //     {
      //       featureType: "boundary",
      //       elementType: "all",
      //       stylers: {
      //         color: "#fefefe",
      //       },
      //     },
      //     {
      //       featureType: "building",
      //       elementType: "all",
      //       stylers: {
      //         color: "#d1d1d1",
      //       },
      //     },
      //     {
      //       featureType: "label",
      //       elementType: "labels.text.fill",
      //       stylers: {
      //         color: "rgba(0,0,0,0)",
      //       },
      //     },
      //   ],
      // },
    },
  });
  const [key, setKey] = useState(1);
  useEffect(() => {
    if (dom.current) {
      cheart.current = echarts.init(dom.current);
      getData();
    }
  }, [dom]);
  function handleFile(e) {
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var xlsxData = new Uint8Array(e.target.result);
      var workbook = XLSX.read(xlsxData, { type: "array" });
      var sheetNames = workbook.SheetNames; // 工作表名称集合
      var worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
      var data = XLSX.utils.sheet_to_json(worksheet);
      fileData.current = data;
      console.log(
        JSON.stringify(
          data.map(({ lng, lat, leiji_chuhuoliang, ...item }) => {
            const halfAYearTotal =
              item["2_chuhuoliang"] +
              item["3_chuhuoliang"] +
              item["4_chuhuoliang"] +
              item["5_chuhuoliang"] +
              item["6_chuhuoliang"] +
              item["7_chuhuoliang"];
            return {
              lng,
              lat,
              leiji_chuhuoliang,
              halfAYearTotal,
            };
          })
        )
      );
      // const bmap = cheart.current.getModel().getComponent("bmap").getBMap();
      gridMap();
      // rendemMark();
      /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(f);
  }
  // 获取数据
  function getData() {
    axios.get("/data.json").then((data) => {
      fileData.current = data.data;
      gridMap();
      var bmap = cheart.current.getModel().getComponent("bmap").getBMap();
      bmapObj.current = bmap;
      const NavigationControl = new window.BMap.NavigationControl({
        anchor: "BMAP_ANCHOR_TOP_RIGHT",
        offset: new window.BMap.Size(110, 110),
      });
      NavigationControl.setAnchor = 100;
      NavigationControl.setOffset = new window.BMap.Size(110, 110);
      bmap.addControl(NavigationControl);
      bmap.addControl(
        new window.BMap.ScaleControl({
          offset: new window.BMap.Size(20, 20),
        })
      ); // 添加比例尺控件
      // bmap.addEventListener("moveend", showOverlaysByView);
      // bmap.addEventListener("zoomend", showOverlaysByView);
    });
  }
  function refreshData(data) {
    if (data) options.current.series[0].data = data;
    options.current.series[0].type = type.current;
    if (init.current) {
      init.current = false;
    } else {
      var bmap = cheart.current.getModel().getComponent("bmap").getBMap();
      options.current.bmap.zoom = bmap.getZoom();
      const { lat, lng } = bmap.getCenter();
      options.current.bmap.center = [lng, lat];
    }
    options.current.visualMap.pieces = buttons[dataType.current].pieces;
    cheart.current.setOption(options.current);
    setKey(key + 1);
  }
  function renderItem(params, api) {
    var lngIndex = api.value(0);
    var latIndex = api.value(1);
    var pointLeftTop = getCoord(params, api, lngIndex, latIndex);
    var pointRightBottom = getCoord(params, api, lngIndex + 1, latIndex + 1);
    return {
      type: "rect",
      shape: {
        x: pointLeftTop[0],
        y: pointLeftTop[1],
        width: pointRightBottom[0] - pointLeftTop[0],
        height: pointRightBottom[1] - pointLeftTop[1],
      },
      style: api.style({
        stroke: "rgba(0,0,0,0.1)",
      }),
      styleEmphasis: api.styleEmphasis(),
    };
  }
  function getCoord(params, api, lngIndex, latIndex) {
    var coords = params.context.coords || (params.context.coords = []);
    var key = lngIndex + "-" + latIndex;
    return (
      coords[key] ||
      (coords[key] = api.coord([
        +(lngExtent[0] + lngIndex * cellSizeCoord[0]).toFixed(6),
        +(latExtent[0] + latIndex * cellSizeCoord[1]).toFixed(6),
      ]))
    );
  }
  // 切换网格热力图与普通热力图
  function toggleType() {
    if (type.current === "heatmap") {
      type.current = "custom";
      gridMap();
    } else {
      type.current = "heatmap";
      heatMap();
    }
  }
  // 渲染网格热力图
  function gridMap() {
    cellData = {};
    fileData.current.forEach(
      ({ lng, lat, leiji_chuhuoliang, halfAYearTotal }) => {
        const h = Math.floor((lng - lngExtent[0]) / cellSizeCoord[0]);
        const s = Math.floor((lat - latExtent[0]) / cellSizeCoord[1]);
        const key = `${h}-${s}`;
        cellData[key] = cellData[key] || 0;
        if (dataType.current === "position") {
          // 工厂数量
          cellData[key] += 1;
        } else if (dataType.current === "bannian") {
          // 半年出货量
          cellData[key] += halfAYearTotal;
        } else {
          // 出货量
          cellData[key] += leiji_chuhuoliang;
        }
      }
    );
    const coordinate = [];
    // for (let i = 0; i < cellCount[1]; i++) {
    //   for (let l = 0; l < cellCount[0]; l++) {
    //     const key = `${l}-${i}`;
    //     coordinate.push([l, i, cellData[key] || 0]);
    //   }
    // }
    for (let i = 0; i < cellCount[0]; i++) {
      for (let l = 0; l < cellCount[1]; l++) {
        const key = `${i}-${l}`;
        coordinate.push([i, l, cellData[key] || 0]);
      }
    }
    refreshData(coordinate);
  }
  // 渲染热力图
  function heatMap() {
    const data = fileData.current.map(
      ({ lng, lat, leiji_chuhuoliang, halfAYearTotal }) => {
        if (dataType.current === "position") {
          // 工厂数量
          return [lng, lat, 1];
        } else if (dataType.current === "bannian") {
          // 半年出货量
          cellData[key] += [lng, lat, halfAYearTotal];
        } else {
          // 出货量
          return [lng, lat, leiji_chuhuoliang];
        }
      }
    );
    refreshData(data);
  }
  // 数据切换
  function toggleData(key) {
    dataType.current = key;
    if (type.current === "heatmap") {
      heatMap();
    } else {
      gridMap();
    }
  }
  //根据视野来显示或隐藏覆盖物
  function showOverlaysByView() {
    var bounds = bmapObj.current.getBounds();
    console.log("bounds");
    boundData.current = bounds;
    // for (var i = 0; i < overlays.length; i++) {
    //     var overlay = overlays[i];
    //     var point = overlay._point;
    //     if (BMapLib.GeoUtils.isPointInRect(point, bounds) || BMapLib.GeoUtils.isPointOnRect(point, bounds)) {
    //         overlay.show();
    //     } else {
    //         overlay.hide();
    //     }
    // }
  }
  return (
    <>
      <div className="ctrl">
        <div className="btns">
          {/* <input onChange={handleFile} type="file" /> */}
          <button onClick={toggleType}>切换热力图</button>
          <div className="buttons">
            {Object.keys(buttons).map((item) => (
              <div
                className={`button ${item === dataType.current && "active"}`}
                onClick={() => toggleData(item)}
                key={item}
              >
                {buttons[item].title}
              </div>
            ))}
          </div>
        </div>
        <div className="title">
          <span>{buttons[dataType.current].title}</span>
          {type.current === "custom" && <span>网格</span>}
          热力图
        </div>
      </div>

      <div className="App" id="main" ref={dom}></div>
    </>
  );
}

export default App;
